import { render, staticRenderFns } from "./NewListEquipement.vue?vue&type=template&id=4dd8b0c1&"
import script from "./NewListEquipement.vue?vue&type=script&lang=js&"
export * from "./NewListEquipement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
installComponents(component, {VCard,VCardText})


/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4dd8b0c1')) {
      api.createRecord('4dd8b0c1', component.options)
    } else {
      api.reload('4dd8b0c1', component.options)
    }
    module.hot.accept("./NewListEquipement.vue?vue&type=template&id=4dd8b0c1&", function () {
      api.rerender('4dd8b0c1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Equipments/NewListEquipement.vue"
export default component.exports